import React, { useContext, useState, useEffect, useRef } from "react";
import { AuthContext } from "store/AuthProvider";
import { StoreContext } from "store/StoreProvider";
import { PickemLayoutPage } from "layouts/PickemLayoutPage";
import { PickemPicksTable } from "components/common/Table/PickemPicksTable";
import { headList, adminHeadlist } from "./model";
//import { Breadcrumbs } from "components/common/Breadcrumbs";
import { NoResults } from "components/common/NoResults";
import { Spinner } from "components/common/Spinner/Spinner";
import { withDialog } from "components/common/withDialog/withDialog";
import { TextInput } from "components/common/Form/TextInput/TextInput";
import PickemCheckbox from "components/common/Form/Checkbox/PickemCheckbox";
import { Select } from "components/common/Form/Select/Select";
//import { Select } from "components/common/Form/Select/Select";
//import Confirmation from "modals/Confirmation";
import { simpleOptions, options } from "utils/helpers";
import "./PickemPicksPage.scss";
//const CryptoJS = require("crypto-js");

//GetPickset -> know competitor, competition, currentWeek, regular weeks + postseason weeks to build the page

const Tiebreakers = ({ tiebreakers, handleTiebreakerInput, tiebreakData }) => {
    console.log("Tiebreakers:", tiebreakers)
    return tiebreakData ? (
        <div className="PickemPicksPage-tiebreakers">
        <h2>Tiebreakers</h2>
        <table>
            <tbody>
                {tiebreakData.map((item, i) => {
                    return (<tr className="PickemPicksPage-tiebreakerRow">
                    <td className="PickemPicksPage-tiebreakerTdRight"> 
                        {item.awayTeam}
                    </td>
                    <td className="PickemPicksPage-tiebreakerInput">
                        <input
                            type="text"
                            title= {`Tiebreaker ${i} Away Score`}
                            value= {tiebreakers[`away${i}`] || ""}
                            code={`tiebreak${i}away`}
                            name={`away${i}`}
                            onChange={handleTiebreakerInput}
                            className="PickemPicksPage-tiebreakerInput"
                        />
                    </td>
                    <td className="PickemPicksPage-tiebreakerTd">
                        @
                    </td>
                    <td className="PickemPicksPage-tiebreakerInput">
                    <input
                            type="text"
                            title= {`Tiebreaker ${i} Home Score`}
                            value= {tiebreakers[`home${i}`] || ""}
                            code={`tiebreak${i}home`}
                            name={`home${i}`}
                            onChange={handleTiebreakerInput}
                            className="PickemPicksPage-tiebreakerInput"
                        />
                    </td >
                    
                    <td className="PickemPicksPage-tiebreakerTdLeft">
                        {item.homeTeam}
                    </td>

                </tr>)

                })}
            </tbody>
        </table>
        </div>
    ) : null
}

const PickemPicksPage = ({
    history,
    match: {
        params: {
            competitionId
        }

    }
    }) => {
    const { functions, accountData, user } = useContext(AuthContext);
    const { updateMeta, competitionData, updateCompetitionData } = useContext(StoreContext);
    const [userPickset, setUserPickset] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isPickLoading, setIsPickLoading] = useState(true)
    const [currentWeek, setCurrentWeek] = useState(null);
    const [selectedWeek, setSelectedWeek] = useState(null);
    const [selection, setSelection] = useState({})
    const [isSelectedLoading, setIsSelectedLoading] = useState(true)
    const [initialEventData, setInitialEventData] = useState({})
    const [nameString, setNameString] = useState(null)
    const [competitionName, setCompetitionName] = useState(null)
    const [competitionDescription, setCompetitionDescription] = useState(null)
    const [errorLoadingPicks, setErrorLoadingPicks] = useState(false)
    const [isSavingPicks, setIsSavingPicks] = useState(false)
    const [initialSelection, setInitialSelection] = useState({})
    const [isSaveEnabled, setIsSaveEnabled] = useState(false)
    const [tiebreakers, setTiebreakers] = useState({})
    const [tiebreakData, setTiebreakData] = useState(null)
    const [tiebreakDataLoading, setTieBreakDataLoading] = useState(true)
    const [initialTiebreakData, setInitialTiebreakData] = useState({})
    const [isCommissioner, setIsCommissioner] = useState(false)
    const [hiddenEvents, setHiddenEvents] = useState({})
    const [hiddenEventsLoading, setHiddenEventsLoading] = useState(true)
    const [initialHiddenEvents, setInitialHiddenEvents] = useState({})

    useEffect(() => {
        updateMeta({
            title: "Picks",
        });
        return clearUser
    }, []);

    useEffect(() => {
        setIsCommissioner(competitionData.isCommissioner === user.uid)
    }, [])

    useEffect(() => {
        let name = ""
        if (competitionData.name) {
            name = competitionData.name
        }
        else {
            if (accountData.firstName && accountData.lastName) {
                name = `${accountData.firstName} ${accountData.lastName}`
            }
            else if (accountData.firstName) {
                name = `${accountData.firstName}`
            }
            else if (accountData.lastName) {
                name = `${accountData.lastName}`
            }
            else if (user.email) {
                name = `${user.email}`
            }
        }
        setNameString(name)
        if (competitionData.competitionDescription) {
            setCompetitionDescription(competitionData.competitionDescription)
        }
        if (competitionData.competitionName) {
            setCompetitionName(competitionData.competitionName)
        }
    }, [competitionData])

    useEffect(() => {
        getPickset(currentWeek)
    }, [])

    useEffect(() => {
        setIsPickLoading(false)
    }, [userPickset])

    useEffect(() => {
        setTieBreakDataLoading(false)
    }, [tiebreakData])

    useEffect(() => {
        setHiddenEventsLoading(false)
    }, [hiddenEvents])

    useEffect(() => {
        setIsSelectedLoading(false)
        analyzeUserPickset()
    }, [selection, initialEventData, initialHiddenEvents, tiebreakers, hiddenEvents])

    const clearUser = () => {
        updateCompetitionData({picksUserId: user.uid, isRandomUser: false, name: null})
    }

    const getPickset = (weekNumber) => {
        const userId = competitionData.picksUserId ? competitionData.picksUserId : user.uid
        functions.httpsCallable('pickemGetPickset')({
            body: JSON.stringify({
                competitionId: competitionId,
                userId: userId,
                isRandomUser: competitionData.isRandomUser,
                currentWeek: weekNumber
            })
        }).then((result) => {
            setIsLoading(false)
            if (result.data.success) {
                setIsLoading(false)
                setIsSelectedLoading(true)
                addUserPicks({...result.data.data.eventData})
                setTiebreakData(() => ([...result.data.data.tiebreakers]))
                setInitialEventData(() => ({...result.data.data.eventData}))
                setInitialTiebreakData(() => ({...result.data.data.userTiebreakerPicks}))
                setInitialHiddenEvents(() => ({...result.data.data.hiddenEvents}))
                setHiddenEvents(() => ({...result.data.data.hiddenEvents}))
                setTiebreakers(() => ({...result.data.data.userTiebreakerPicks}))
                if (!weekNumber) {
                    setCurrentWeek(result.data.data.currentWeek) 
                }
            } else {
                setIsLoading(false)
                setIsSelectedLoading(false)
                setErrorLoadingPicks(true)
            }
        })
    }

    const addUserPicks = (userData) => {
        if (userData.currentWeek) {
            const selectedInfo = {}
            for (const pick of userData.currentWeek) {
                selectedInfo[pick.eventId] = pick.userPick
            }
            setSelection(() => ({...selectedInfo}))
            setInitialSelection(() => ({...selectedInfo}))
        }
        
    }

    const handleRadioChange = (
        value, eventId
    ) => {
        if (Object.keys(selection).length > 0) {
            if (isCommissioner || competitionData.picksUserId === user.uid) {
                setSelection((prevSelect) => ({
                    ...prevSelect,
                    [eventId]: value
                }))
            }
        }
    };

    const handleHideEventCheck = (eventId, checked) => {
        let hiddenEventsNew = {...hiddenEvents}
        if (hiddenEventsNew[eventId]) {
            hiddenEventsNew[eventId] = !hiddenEventsNew[eventId]
        }
        else {
            hiddenEventsNew[eventId] = true
        }

        setHiddenEvents({...hiddenEventsNew })
    }

    const analyzeUserPickset = () => {
        if (initialEventData.currentWeek) {
            let saveEnabled = false
            const userPicksetData = {
                currentWeek: [],
                regWeeks: initialEventData.regWeeks,
                postWeeks: initialEventData.postWeeks
            }
            let totalPoints = 0
            //console.log("Analyze Picks Current Data:", currentData)
            for (const event of initialEventData.currentWeek) {

                if (!isCommissioner && hiddenEvents[event.eventId]) {
                    continue;
                }
                if (!saveEnabled)
                    saveEnabled = selection[event.eventId] !== initialSelection[event.eventId]

                
                const favoritePicked = (<div><input
                    type="radio"
                    id="favorite"
                    key={`${event.eventId}-favorite`}
                    value={event.favorite}
                    checked={
                        selection[event.eventId] === event.favorite
                    }
                    onChange={() =>
                        handleRadioChange(
                            event.favorite, event.eventId
                        )
                    }
                /></div>)
                const vs = "vs"
                const underdogPicked = (<div><input
                    type="radio"
                    id="underdog"
                    key={`${event.eventId}-underdog`}
                    value={event.underdog}
                    checked={
                        selection[event.eventId] === event.underdog
                    }
                    onChange={() =>
                        handleRadioChange(
                            event.underdog, event.eventId
                        )
                    }
                /></div>)
    
                let userPickObj = {
                    ...event,
                    underdogPicked: underdogPicked,
                    vs: vs,
                    favoritePicked: favoritePicked,
                    id: event.eventId
                }

                if (isCommissioner) {
                    const hideEventButton = (
                        <div className="PickemPicksPage-hideEventDiv">
                            <PickemCheckbox
                            inputData={{
                                id: event.eventId,
                                checked: hiddenEvents[event.eventId],
                                value: event.eventId,
                                }}
                                onChange={handleHideEventCheck}
                            />
                        </div>
                    )
                    userPickObj = {
                        ...userPickObj,
                        hide: hideEventButton
                    }
                }
                totalPoints += event.point
                userPicksetData.currentWeek.push(userPickObj)
            }
            const totalObj = {
                favorite: " ",
                favoritePicked: " ",
                vs: " ",
                underdogPicked: " ",
                underdog: " ",
                open: " ",
                monday: " ",
                tuesday: " ",
                wednesday: " ",
                pointSpreadFavorite: " ",
                startAt: "TOTAL POINTS",
                point: totalPoints
            }

            userPicksetData.currentWeek.push(totalObj)

            for (const key of Object.keys(tiebreakers)) {
                if (!saveEnabled)
                    saveEnabled = initialTiebreakData[key] !== tiebreakers[key]
            }

            for (const key of Object.keys(hiddenEvents)) {
                if (Object.keys(initialHiddenEvents) > 0) {
                    if (!saveEnabled)
                        saveEnabled = initialHiddenEvents[key] !== hiddenEvents[key]
                }
                else {
                    if (!saveEnabled)
                        saveEnabled = hiddenEvents[key]
                }
                
            }
            setIsSaveEnabled(saveEnabled)
            setUserPickset(userPicksetData)
        }
        
    }

    const weekClicked = (weekData) => {
        if (!weekData.isPost) {
            getPickset(weekData.e.target.value)
            setSelectedWeek(weekData.e.target.value)
            setIsPickLoading(true)
        }
        
    }

    const createWeeks = (numWeeks, isPost) => {
        const weekList = []
        const selected = {}
        for (let weekNumber = 1; weekNumber <= numWeeks; weekNumber++) {
            const isCurrentWeek = !isPost && currentWeek === weekNumber
            selected[weekNumber] = isCurrentWeek
            //weekList.push((<div key={weekNumber} onClick={weekClicked} className={`col PickemPicksPage-weeks ${isCurrentWeek ? "PickemPicksPage-selected": null}`}><p>{weekNumber}</p></div>))
            weekList.push((<button key={weekNumber} value={weekNumber} onClick={e => weekClicked({e, isPost}, "value")} className={`PickemPicksPage-weekButton ${isCurrentWeek ? "PickemPicksPage-currentWeek": "PickemPicksPage-notCurrentWeek"} ${Number(selectedWeek) === weekNumber ? "PickemPicksPage-selectedWeek": null}`}>{weekNumber}</button>))
        }
        return weekList
    }

    const handleSavePicks = () => {
        const userId = competitionData.picksUserId ? competitionData.picksUserId : user.uid

        const hiddenList = Object.keys(hiddenEvents).filter((key) => hiddenEvents[key])
        const savePicksetObj = {
            competitionId: competitionId,
            currentWeek: currentWeek,
            pickData: selection,
            tiebreakers: tiebreakers,
            isRandomUser: competitionData.isRandomUser,
            hiddenEvents: hiddenList,
            userId: userId
        }
        setIsSavingPicks(true)
        functions.httpsCallable('pickemSavePickset')({
            body: JSON.stringify({...savePicksetObj})
        }).then((result) => {
            setIsSavingPicks(false)
            if (result.data.success) {
                setIsSaveEnabled(false)
            }
        }) 

    }

    const handleTiebreakerInput = (e) => {
        const tiebreakerUpdate = {}
        tiebreakerUpdate[`${e.target.name}`] = e.target.value
        setTiebreakers(current => ({
            ...current,
            ...tiebreakerUpdate
        }))

    }

    const handleCancelPicks = () => {
        addUserPicks({...initialEventData})
        setTiebreakers({...initialTiebreakData})
        setHiddenEvents({...initialHiddenEvents})
    }



    const SaveButton = () => {
        return (
            <button style={{ fontSize: "1em", marginLeft: "5em" }}
            className={`btn-primary ${!isSaveEnabled ? "PickemPicksPage-disabled" : null}`} onClick={handleSavePicks} disabled={!isSaveEnabled}
        >
            Save
        </button>
        )
    }

    const CancelButton = () => {
       return ( <button style={{ fontSize: "1em", marginLeft: "5em" }}
            className={`btn-secondary ${!isSaveEnabled ? "PickemPicksPage-disabled" : null}`} onClick={handleCancelPicks} disabled={!isSaveEnabled}
        >
            Cancel
        </button>
       )
    }

    return (
        <PickemLayoutPage
            title={`${competitionData.userPicksetName} Picks`}
            name={nameString} 
            competitionName={competitionName} 
            competitionDescription={competitionDescription}
            isDifferentUser={competitionData.picksUserId ? competitionData.picksUserId !== user.uid : false}
            subtitle={
                <>
                    {
                        <div style={{ display: "flex", fontSize: "1em", margin: 10 }}>
                            
                                <SaveButton />
                                <CancelButton />
                            
                        </div>
                    }
                </>
            }
        >
            <div className="PickemPicksPage">
            <div className="PickemPicksPage-weekView">
                {!isLoading && userPickset.regWeeks ? <div className="PickemPicksPage-weeks">
                    <div className="PickemPicksPage-weeks">
                        <div style={{margin: '0.25em'}}><p>Regular</p></div>
                        {
                            createWeeks(userPickset.regWeeks, false)
                        }
                    </div>
                </div> : null}
                {!isLoading && userPickset.postWeeks ? <div className="PickemPicksPage-weeks">
                
                    <div className="PickemPicksPage-weeks">
                        <div style={{padding: '0.1em'}}><p>Post</p></div>
                        {
                            createWeeks(userPickset.postWeeks, true)
                        }
                    </div>
                </div> : null}
            </div>
            {!isPickLoading && userPickset.currentWeek && !tiebreakDataLoading && !isSelectedLoading && !isSavingPicks && !hiddenEventsLoading ? 
                <div>
                    <PickemPicksTable
                    subject="picks"
                    headList={isCommissioner ? adminHeadlist : headList}
                    list={userPickset.currentWeek}
                    selected={[]}
                    /> 
                    <Tiebreakers 
                        tiebreakData={tiebreakData}
                        tiebreakers={tiebreakers}
                        handleTiebreakerInput={handleTiebreakerInput}/>
                    <div style={{ display: "flex", fontSize: "1em", margin: 0.5 }} className="PickemPicksPage-saveButtons">
                        <SaveButton />
                        <CancelButton />
                    </div>
                </div>
                : !errorLoadingPicks ? <Spinner></Spinner> : <NoResults
                label={"Error retrieving pickset"}
            />}
                </div>
            
        </PickemLayoutPage>
    );
};

export default withDialog(PickemPicksPage);
