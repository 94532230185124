import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "store/AuthProvider";
import { StoreContext } from "store/StoreProvider";
import { PickemLayoutPage } from "layouts/PickemLayoutPage";
import { PickemTable } from "components/common/Table/PickemTable";
import { Link } from "react-router-dom";
import { headList } from "./model";
//import { Breadcrumbs } from "components/common/Breadcrumbs";
//import { NoResults } from "components/common/NoResults";
import { Spinner } from "components/common/Spinner/Spinner";
import { withDialog } from "components/common/withDialog/withDialog";
import { TextInput } from "components/common/Form/TextInput/TextInput";
import Checkbox from "components/common/Form/Checkbox/Checkbox";
import { Select } from "components/common/Form/Select/Select";
//import { Select } from "components/common/Form/Select/Select";
import Confirmation from "modals/Confirmation";
import { simpleOptions, options } from "utils/helpers";
import "./PickemAccountPage.scss";
import { Icon } from "components/common/Icons/Icon";
//const CryptoJS = require("crypto-js");

// PLayer settings: true/false make auto picks for me, logic for default (choose by: random, home team, away team, favorite-home, favorite-away, underdog-home, underdog-away)
// picksetname

//League: non commission show but not change (see commissioner for changes)
//Commish: edit league name, deadline for picks, password, spread on off toggle

//Another section for members (see commissioner if not commish). Show: display name, pickset name, commish: remove button

/**
 * 
 * Time Deadline Options for Compeitions, duplicated in create pickem page
 * 
 */
 const picksOptions = simpleOptions([
    "Favorite",
    "Underdog",
    "Home",
    "Away"
])

const PickemAccountPage = ({
    history,
    location,
    openModal,
    closeModal
    }) => {
    const { functions, accountData, user } = useContext(AuthContext);
    const { updateMeta, competitionData } = useContext(StoreContext);
    const [isLoading, setIsLoading] = useState(true);
    const [nameString, setNameString] = useState(null)
    const [competitionName, setCompetitionName] = useState(null)
    const [competitionDescription, setCompetitionDescription] = useState(null)
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [defaultPickOption, setDefaultPickOption] = useState(null)

    useEffect(() => {
        updateMeta({
            title: "Account Settings",
        });
    }, []);

    useEffect(() => {
        let name = ""
        if (accountData.firstName && accountData.lastName) {
            name = `${accountData.firstName} ${accountData.lastName}`
            setFirstName(accountData.firstName)
            setLastName(accountData.lastName)
        }
        else if (accountData.firstName) {
            name = `${accountData.firstName}`
            setFirstName(accountData.firstName)
        }
        else if (accountData.lastName) {
            name = `${accountData.lastName}`
            setLastName(accountData.lastName)
        }
        else if (user.email) {
            name = `${user.email}`
        }
        setNameString(name)
        setCompetitionDescription(competitionData.competitionDescription)
        setCompetitionName(competitionData.competitionName)
        console.log("Default Picks:" , accountData?.pickemDefaultPicks)
        setDefaultPickOption(accountData?.pickemDefaultPicks || null)
    }, [])


    const confirm = (action) => {
        let message = '';
        let title = '';
        switch (action) {
            case 'ProfileUpdated':
                title = "Success"
                message = "Profile Successfully Updated"
                break
            case 'ProfileUpdateFailed':
                title = "error"
                message = "Failed to Update Profile"
                break
            default:
                title = "Error"
                message = "An Error Has Occurred"
        }
        openModal({
            title: title,
            content: (
                <Confirmation
                    message={message}
                    closeModal={closeModal}
                    buttons={false}
                    onConfirm={(e) => {
                        closeModal();
                    }}
                />
            ),
        }, {
            reload: true
        });
    };

    const handleSaveAccountSettings = () => {
        const updateObject = {
            firstName: firstName,
            lastName: lastName,
            pickemDefaultPicks: defaultPickOption,
        }
        functions.httpsCallable("profileUpdate")({userId: user.uid, body: JSON.stringify(updateObject)}).then((result => {
            if (result.data.success) {
                confirm("ProfileUpdated")             
            }
            else {
                confirm("ProfileUpdateFailed")
            }
        }))
    }

    return (
        <PickemLayoutPage
            title="Account"
            name={nameString} 
            competitionName={competitionName} 
            competitionDescription={competitionDescription}
        >
            <div className="PickemAccountPage">
                 
                <div>
                    <h1>Account Settings</h1>
                    <p style={{margin: '0.5em'}}>First Name:</p>
                    <div className="PickemAccountPage-accountinfo" style={{margin: '0.5em'}}>
                    <TextInput
                        inputData={{
                            title: "Update First Name",
                            placeholder: "First Name",
                            value: firstName || "",
                            type: "text",
                        }}
                        code="name"
                        onChange={setFirstName}
                    />
                    </div>
                    <p style={{margin: '0.5em'}}>Last Name:</p>
                    <div className="PickemAccountPage-accountinfo" style={{margin: '0.5em'}}>
                    <TextInput
                        inputData={{
                            title: "Update Last Name",
                            placeholder: "Last Name",
                            value: lastName || "",
                            type: "text",
                        }}
                        code="name"
                        onChange={setLastName}
                    />
                    </div>
                    {defaultPickOption ? <div className="PickemAccountPage-accountinfo" style={{margin: '0.5em'}}>
                        <p style={{margin: '0.5em 0em'}}>Global Default Picks Setting:</p>
                            <Select
                            placeholder="Default Pick Select..."
                            title="Set Default Picks Option"
                            options={picksOptions}
                            onChange={setDefaultPickOption}
                            optionKey="value"
                            value={defaultPickOption}
                            code="picks"
                        />
                    </div> : null}
                    <div className="PickemAccountPage-accountinfo" style={{margin: '0.5em'}}>
                        <button onClick={handleSaveAccountSettings} style={{ fontSize: "1em", marginTop: '1em' }}
                            className="btn-primary"
                        >
                            Save
                        </button>
                    </div>
                    
                </div>
                
            </div>
            
        </PickemLayoutPage>
    );
};

export default withDialog(PickemAccountPage);
