import React from "react";
import { Icon } from "components/common/Icons/Icon";
import { pluck } from "utils/helpers";
import moment from "moment-timezone";
import { priceTransform } from "utils/helpers";
import Confirmation from "modals/Confirmation";
import "./Table.scss";

const noImage = "/images/no-image.png";

/**
 *  Table
 *
 */
export const PickemPicksTable = ({
    list,
    sortBy,
    sortOrder,
    sortFunc,
    selectFunc,
    subject,
    headList,
    isSelectMode,
    selected,
}) => {
    const numSelected = selected.length;

    /**
     *  Sort update
     *
     */
    const updateSorting = (e) => {
        let key = e.currentTarget.dataset.by,
            params =
                sortBy !== key
                    ? { sortBy: key, sortOrder: "asc" }
                    : {
                          sortBy: key,
                          sortOrder: sortOrder === "asc" ? "desc" : "asc",
                      };
        sortFunc(params.sortBy, params.sortOrder);
    };

    /**
     *  Table Head
     *
     */
    const Head = () => (
        <thead>
            <tr>
                {isSelectMode ? (
                    <th>
                        <input
                            type="checkbox"
                            style={{ padding: "0 12px" }}
                            checked={numSelected === list.length}
                            onChange={(e) =>
                                selectFunc(
                                    numSelected === list.length
                                        ? []
                                        : pluck(list, "id")
                                )
                            }
                        />
                    </th>
                ) : null}
                {headList.map((item) => {
                    //
                    const classList = [];
                    // visibility
                    if (item.mobileHide) classList.push("mobileHide");
                    // sorting
                    if (sortBy === item.code) {
                        classList.push("active");
                        if (sortOrder === "desc") classList.push("down");
                        else classList.push("up");
                    }
                    // cursor type
                    if (item.isUnsortable) classList.push("unsortable");
                    // render
                    return (
                        <th
                            key={item.code}
                            style={{textAlign: item.alignment}}
                            className={classList.join(" ")}
                            data-by={item.code}
                            onClick={item.isUnsortable ? null : updateSorting}
                        >
                            {item.title}
                            <Icon name="chevron" />
                        </th>
                    );
                })}
            </tr>
        </thead>
    );

    /**
     *  Table Body
     *
     */
    const Body = () => (
        <tbody>
            {list.map((obj) => {
                return (
                    <tr
                        data-id={obj.id}
                        key={`${subject}-${obj.id}`}
                    >
                        {isSelectMode ? (
                            <td>
                                <input
                                    type="checkbox"
                                    style={{ padding: "0 12px" }}
                                    readOnly
                                    checked={
                                        selected.indexOf(obj.id) === -1
                                            ? false
                                            : true
                                    }
                                />
                            </td>
                        ) : null}
                        {headList.map((item) => (
                            <RenderTD
                                key={`d-${item.code}`}
                                field={item}
                                data={obj}
                                alignment={item.alignment}
                            />
                        ))}
                    </tr>
                );
            })}
        </tbody>
    );

    /**
     *  Render
     *
     */
    return (
        <div className="Table">
            <table>
                <Head />
                <Body />
            </table>
        </div>
    );
};

/**
 *  Render TD
 *
 */
export const RenderTD = ({ field, data, alignment }) => {
    const classList = [];
    // visibility
    if (field.mobileHide) classList.push("mobileHide");

    return (
        <td
            className={classList.join(" ")}
            title={field.hover && (parseKeyValue(field.hover, data) || "")}
            style={{textAlign: alignment}}
        >
            <TransformTD
                field={field}
                data={data}
                hasData={!!Object.keys(data).length}
            />
        </td>
    );
};

/**
 *  Get TD Value
 *
 */
const TransformTD = ({ field, data, hasData }) => {
    const rawValue = parseKeyValue(field.code, data);
    if (field.code === "point") {
        console.log("Point:", rawValue)
    }
    if (rawValue !== 0 && !rawValue && !hasData) 
        return "-";
    // render based on type
    switch (field.type) {
        case "date":
            return moment.unix(rawValue).format(field.format || "MMM DD YYYY");
        case "dateString":
            if (rawValue === "TOTAL POINTS")
                return rawValue
            //return moment(rawValue.indexOf('Z')===-1?rawValue+'Z':rawValue).tz('America/New_York').format(field.format || "MMM DD YYYY");
            return moment(rawValue.indexOf('Z')===-1?rawValue+'Z':rawValue).format(field.format || "MMM DD YYYY");
        case "price":
            return priceTransform(rawValue);
        case "image":
            return <img src={rawValue || noImage} />;
        case "icon":
            return <img className="thumb" src={rawValue || noImage} />;
        case "withTag":
            return <RenderValueWithTag value={rawValue} tags={field.tagGroup ? data[field.tagGroup] : data.tags} label={field.tagGroup || "tags"} />;
        default:
            return field.transformClass ? <span className={field.transformClass}>{rawValue}</span> : rawValue ? rawValue : rawValue === 0 ? 0 : '-';
    }
};

/**
 * Render Tag
 *
 */
const RenderValueWithTag = ({ value, tags, label }) => {
    if (!tags || !tags.length) {
        return value ? value : '-';
    } 

    const maxLength = 12;
    const tagName = (tag) => {
        if (!tag.name) return "";
        return tag.name.length > maxLength
            ? tag.name.substr(0, maxLength) + "..."
            : tag.name;
    };

    return tags.map((t, i) => (
        <span 
            key={`${label}-${t.name}-${i}`} 
            className="tag"
        >
            {tagName(t)}
        </span>
    ));
};

/**
 *  Parse Key Value
 *
 *  Handle nested data or return as is
 *
 */
const parseKeyValue = (code, data) => {
    let rawValue;
    // with nesting
    if (code.indexOf(".") !== -1) {
        // split
        const keys = code.split(".");
        //
        let currentValue = data[keys[0]];
        if (!currentValue) return;
        while (keys.length > 1) {
            keys.shift();

            currentValue = currentValue[keys[0]];
            if (!currentValue) return;
        }
        rawValue = currentValue;
    } else {
        // no nesting
        rawValue = data[code];
    }
    return rawValue;
};

/**
 *  Select Mode Button
 *
 */
export const SelectModeButton = ({ action, isActive }) => {
    const classList = ["SelectModeButton"];

    if (isActive) classList.push("active");

    return (
        <div className={classList.join(" ")} onClick={(e) => action()}>
            <Icon name="list" />
        </div>
    );
};

/**
 *  Table Subtitle
 *
 */
export const TableSubtitle = ({
    subject,
    selectFunc,
    removeFunc,
    selected,
    openModal,
    closeModal,
    action
}) => {

    const actionType = action || "delete";
    /**
     *  Confirm func
     *
     */
    const confirm = (e) => {
        e.preventDefault();

        let message = `Do you want to ${actionType} ${selected.length} ${subject}${
            selected.length !== 1 ? "s" : ""
        }?`;

        let handleConfirm = (extraOption, destination) => {
            removeFunc(selected, extraOption, destination);
            closeModal();
        };

        openModal({
            title: "Confirmation",
            content: (
                <Confirmation
                    message={message}
                    closeModal={closeModal}
                    buttons={actionType}
                    onConfirm={handleConfirm}
                />
            ),
        });
    };

    /**
     *  render
     *
     */
    return (
        <div className="TableSubtitle">
            <div>
                <span onClick={(e) => selectFunc([])}>
                    <Icon name="close" />
                </span>{" "}
                {selected.length} {subject}(s) selected
            </div>

            <button
                className="btn-primary"
                onClick={confirm}
                title={`${actionType} selected`}
            >
                {actionType}
            </button>
        </div>
    );
};
