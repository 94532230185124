import React, { useState, useEffect, useContext, createContext } from "react";
import { AuthContext } from "store/AuthProvider";
import { getGameId } from "../utils/helpers";

/**
 * Store Context
 *
 */
export const StoreContext = createContext({
    store: {
        gridQuery: {},
        meta: {},
        flash: {},
        spinner: {},
        leagues: {
            list: [],
            isFetching: true,
        },
        teams: {
            list: [],
            isFetching: true,
        },
        mapping: {
            list: null,
            isFetching: true,
        },
    },
    updateMeta: () => {},
    updateFlash: () => {},
    showSpinner: () => {},
    fetchLeagues: () => {},
    fetchTeams: () => {}
});

/**
 * Store Provider
 *
 */
const StoreProvider = ({ children }) => {
    const { functions } = useContext(AuthContext);

    // grid query
    const [gridQuery, setGridQuery] = useState("")

    // meta
    const [meta, setMeta] = useState({
        title: "Meta Title",
        description: "Meta Description",
    });
    const updateMeta = (newMeta) => {
        setMeta({ ...meta, ...newMeta });
    };

    // flash
    const [flash, setFlash] = useState({ message: false, success: false });
    const updateFlash = (data = null) => {
        setFlash(data ? { ...flash, ...data } : { ...flash, message: null });
    };

    // spinner
    const [spinner, setSpinner] = useState({ active: false, message: null });
    const showSpinner = (value, message=null) => {
        setSpinner({ active: value, message });
    };

    // leagues
    const [leagues, setLeagues] = useState({
        list: [],
        isFetching: true,
    });
    const fetchLeagues = () =>
        functions
            .httpsCallable("getLeagues")()
            .then((res) =>
                setLeagues({
                    list: appleAdjustments(res.data.data),
                    isFetching: false,
                })
            )
            .catch((err) => {
                setLeagues({ ...leagues, isFetching: false });
                console.log(err);
            });
    const appleAdjustments = (data) => {
        return data.map((item) => {
            const odds = [];
            const scores = [];
            if (item.bovadaId) odds.push({ name: "bovada" });
            if (item.sdioKey) scores.push({ name: "sdio" });
            if (item.jsonOddsId) {
                odds.push({ name: "jsonodds" });
                if (item.sport !== "soccer" || item.jsonOddsLeague)
                    scores.push({ name: "jsonodds" });
            }
            return {
                ...item,
                odds,
                scores,
                sportId: getGameId(item.sport),
            };
        });
    };

    // teams
    const [teams, setTeams] = useState({
        list: [],
        isFetching: true,
    });
    const [mapping, setMapping] = useState({
        list: null,
        isFetching: true,
    });
    const fetchTeams = () => {
        // teams
        functions
            .httpsCallable("getTeams")()
            .then((res) =>
                setTeams({
                    list: res.data.data,
                    isFetching: false,
                })
            )
            .catch((err) => {
                setTeams({ ...teams, isFetching: false });
                console.log(err);
            });
        // mapping 
        functions
            .httpsCallable("getMapping")()
            .then((res) =>
                setMapping({
                    list: res.data.data,
                    isFetching: false,
                })
            )
            .catch((err) => {
                setMapping({ ...teams, isFetching: false });
                console.log(err);
            });
    }

    //Pickem Competition Data
    const [competitionData, setCompetitionData] = useState({
        name: null,
        competitionName: "",
        competitionDescription: "",
        isCommissioner: false,
        password: null,
        picksUserId: null,
        isRandomUser: false,
        userPicksetName: ""
    })

    const getCompData = () => {
        const compData = JSON.parse(localStorage.getItem('compData'));
        if (compData) {
            setCompetitionData({...compData});
        }
    }
    const updateCompetitionData = (updates) => {
        localStorage.setItem('compData', JSON.stringify({...competitionData, ...updates}));
        setCompetitionData(() => ({...competitionData, ...updates})
    )}

    // on mount
    useEffect(() => {
        fetchLeagues();
        fetchTeams();
        getCompData();
    }, []);

    return (
        <StoreContext.Provider
            value={{
                meta,
                flash,
                spinner,
                leagues,
                teams,
                mapping,
                gridQuery,
                competitionData,
                updateMeta,
                updateFlash,
                showSpinner,
                fetchLeagues,
                fetchTeams,
                setGridQuery,
                updateCompetitionData
            }}
        >
            {children}
        </StoreContext.Provider>
    );
};
export default StoreProvider;
