import React, { useContext, useState, useEffect, useRef } from "react";
import { AuthContext } from "store/AuthProvider";
import { StoreContext } from "store/StoreProvider";
import { PickemLayoutPage } from "layouts/PickemLayoutPage";
//import { Breadcrumbs } from "components/common/Breadcrumbs";
//import { NoResults } from "components/common/NoResults";
import { Spinner } from "components/common/Spinner/Spinner";
import { withDialog } from "components/common/withDialog/withDialog";
import { TextInput } from "components/common/Form/TextInput/TextInput";
import Checkbox from "components/common/Form/Checkbox/Checkbox";
import { Select } from "components/common/Form/Select/Select";
//import { Select } from "components/common/Form/Select/Select";
import Confirmation from "modals/Confirmation";
import { simpleOptions, options } from "utils/helpers";
import "./PickemCreatePage.scss";
const CryptoJS = require("crypto-js");

/**
 * 
 * Tournament Type Ids and Leagues
 * Table is duplicated in create tournament cloud function
 * 
 */
const tournamentType = [
    {name: "Pro Football", id: 1, leagueIds: ["nfl"], },
    {name: "College Football", id: 2, leagueIds: ["ncaaf"], },
    {name: "Pro & College Football", id: 3, leagueIds: ["nfl", "ncaaf"], },
    {name: "Baseball", id: 4, leagueIds: ["mlb"], },
    {name: "Basketball", id: 5, leagueIds: ["nba"], },
    {name: "College Basketball", id: 6, leagueIds: ["ncaam"], },
    {name: "Hockey", id: 7, leagueIds: ["nhl"], },
    {name: "Omnium", id: 8, leagueIds: ["nfl", "ncaaf", "nba", "ncaam", "nhl", "mlb"], }
]

/**
 * 
 * Time Deadline Options for Compeitions
 * 
 */
 const competitionTypeOptions = options(tournamentType)

/**
 * 
 * Time Deadline Options for Compeitions, duplicated in settings page
 * 
 */
const deadlineOptions = simpleOptions([
    "5 minutes before each event start time",
    "5 minutes before first event start time",
    "1 Hour before each event start time",
    "None"
])


const PickemCreatePage = ({history, openModal, closeModal}) => {
    const { functions } = useContext(AuthContext);
    const { updateMeta, competitionData, updateCompetitionData } = useContext(StoreContext);
    const [competitionName, setCompetitionName] = useState(null)
    const [competitionPassword, setCompetitionPassword] = useState(null)
    const [useSpread, setUseSpread] = useState(false)
    const [deadline, setDeadline] = useState(null)
    const [competitionType, setCompetitionType] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        updateMeta({
            title: "Create League",
        });
    }, []);

    const handleUseSpreadChange = () => {
        setUseSpread(!useSpread)
    }

    const confirm = (action) => {
        let message = '';
        switch (action) {
            case 'NoCompType':
                message = "League Type Is Required"
                break
            case 'NoCompName':
                message = "League Name Is Required"
                break
            case 'NoPassword':
                message = "Password Is Required"
                break
            case 'NoDeadline':
                message = "Deadline Is Required"
                break
            default:
                message = "An Error Has Occurred"
        }
        openModal({
            title: "Error",
            content: (
                <Confirmation
                    message={message}
                    closeModal={closeModal}
                    buttons={false}
                    onConfirm={(e) => {
                        closeModal();
                    }}
                />
            ),
        });
    };

    const handleCreateCompetition = () => {
        if (!competitionName) {
            confirm("NoCompName")
            return
        }
        if (!competitionType) {
            confirm("NoCompType")
            return
        }
        if (!deadline) {
            confirm("NoDeadline")
            return
        }
        setIsLoading(true)
        let competitionTypeId = competitionTypeOptions.filter(c => c.name === competitionType)
        competitionTypeId = competitionTypeId[0].id
        const leagueObject = {
            competitionName,
            competitionPassword,
            useSpread,
            deadline,
            competitionType,
            competitionTypeId 
        }
        functions.httpsCallable("pickemCompetitionCreate")({body: JSON.stringify(leagueObject)}).then((result => {
            console.log("Result:", result)
            setIsLoading(false)
            if (result.data.success) {
                updateCompetitionData({password: result.data.data.password})
                history.push(`/pickem-join/${result.data.data.id}`)
            }
        }))
        
    }

    return (
        <PickemLayoutPage
            title="Create League"
        >
            {!isLoading ? <div className="PickemCreatePage">
                <div className="col">
                    <p style={{margin: '0.5em'}}>League Name:</p>
                    <div className="PickemCreatePage-leagueinfo" style={{margin: '0.5em'}}>
                        <TextInput
                        inputData={{
                            title: "Enter a League Name",
                            placeholder: "League Name",
                            value: competitionName || "",
                            type: "text",
                        }}
                        code="name"
                        onChange={setCompetitionName}
                        />
                    </div>
                    <p style={{margin: '0.5em'}}>League Password:</p>
                    <div className="PickemCreatePage-leagueinfo" style={{margin: '0.5em'}}>
                        <TextInput
                            inputData={{
                                title: "Enter a League Password",
                                placeholder: "League Password",
                                value: competitionPassword || "",
                                type: "text",
                            }}
                            code="password"
                            onChange={setCompetitionPassword}
                        />
                    </div>
                    <p style={{margin: '0.5em'}}>League Type:</p>
                    <div className="PickemCreatePage-leagueinfo" style={{margin: '0.5em'}}>
                        <Select
                            placeholder="Select League Type.."
                            title="Leaguetype"
                            options={competitionTypeOptions}
                            onChange={setCompetitionType}
                            optionKey="name"
                            value={competitionType}
                            code="competitiontype"
                        />
                    </div>
                    <div className="PickemCreatePage-leagueinfo" style={{margin: '0.5em'}}>
                    <Checkbox
                        inputData={{
                            id: 1000,
                            checked: useSpread,
                            name: "Use point spread to determine game outcomes?"
                        }}
                        onChange={handleUseSpreadChange}
                    />
                    </div>
                    <div className="PickemCreatePage-leagueinfo" style={{margin: '0.5em'}}>
                        <p style={{margin: '0.5em 0em'}}>How long prior to start should picks lock:</p>
                        <Select
                            placeholder="Select Picks Deadline..."
                            title="Deadline"
                            options={deadlineOptions}
                            onChange={setDeadline}
                            optionKey="value"
                            value={deadline}
                            code="deadline"
                        />
                           
                    </div>
                    <div className="PickemCreatePage-leagueinfo" style={{margin: '0.5em'}}>
                        <button onClick={handleCreateCompetition} style={{ fontSize: "1em", marginTop: '1em' }}
                                className="btn-primary"
                        >
                            Create and Continue
                        </button>
                </div>
                
                </div>
                
                
                
            </div> : <Spinner></Spinner>}
            
        </PickemLayoutPage>
    );
};

export default withDialog(PickemCreatePage);
