import React, { useContext, useState, useEffect, useRef } from "react";
import { AuthContext } from "store/AuthProvider";
import { StoreContext } from "store/StoreProvider";
import { PickemLayoutPage } from "layouts/PickemLayoutPage";
import { Link } from "react-router-dom";
import { Spinner } from "components/common/Spinner/Spinner";
import { withDialog } from "components/common/withDialog/withDialog";
import "./PickemSchedulePage.scss";

//GetPickset -> know competitor, competition, currentWeek, regular weeks + postseason weeks to build the page

const PickemSchedulePage = ({
    history,
    location,
    match: {
        params: {
            competitionId
        }

    }
    }) => {
    const { functions, accountData, user } = useContext(AuthContext);
    const { updateMeta, competitionData, updateCompetitionData } = useContext(StoreContext);
    const [isLoading, setIsLoading] = useState(true);
    const [scheduleData, setScheduleData] = useState({});
    const [nameString, setNameString] = useState(null)
    const [competitionName, setCompetitionName] = useState(null)
    const [competitionDescription, setCompetitionDescription] = useState(null)

    useEffect(() => {
        updateMeta({
            title: "Schedule",
        });
    }, []);

    useEffect(() => {
        let name = ""
        if (accountData.firstName && accountData.lastName) {
            name = `${accountData.firstName} ${accountData.lastName}`
        }
        else if (accountData.firstName) {
            name = `${accountData.firstName}`
        }
        else if (accountData.lastName) {
            name = `${accountData.lastName}`
        }
        else if (user.email) {
            name = `${user.email}`
        }
        
        setNameString(name)
        if (competitionData.competitionDescription) {
            setCompetitionDescription(competitionData.competitionDescription)
        }
        if (competitionData.competitionName) {
            setCompetitionName(competitionData.competitionName)
        }
    }, [competitionData])

    useEffect(() => {
        getSchedule()
    }, [])

    const getSchedule = (weekNumber) => {
        functions.httpsCallable('pickemGetSchedulePage')({
            body: JSON.stringify({
                competitionId: competitionId,
            })
        }).then((result) => {
            setIsLoading(false)
            console.log("Schedule Result:", result.data)
            if (result.data.success) {
                setIsLoading(false)
                setScheduleData(() => ({...result.data.data}))
            }
        })
    }

    const createSchedule = () => {
        const schedulePage = []
        if (scheduleData.matchupSchedule) {
            for (const matchup of Object.keys(scheduleData.matchupSchedule)) {
                const weekName = matchup.split("_")
                const header = (
                    <h1>{weekName[0]} {weekName[1].replace(/^0+/, '')}</h1>
                )
                
                const matchups = scheduleData.matchupSchedule[matchup].map((item) => {
                    return (
                            <tr>
                                <td className="PickemSchedulePage-teamNameAway">
                                    <Link to={{pathname: `/pickem-picks/${competitionId}`}} onClick={() => updateCompetitionData({name: item.awayPicksetName, picksUserId: item.awayCompetitor, isRandomUser: item.isRandomAway})}>
                                        {item.awayPicksetName}
                                    </Link>
                                </td>
                                <td className="PickemSchedulePage-teamScore">
                                    {item.awayScore}
                                </td>
                                <td className="PickemSchedulePage-teamScore">
                                    <p>VS</p>
                                </td>
                                <td className="PickemSchedulePage-teamScore">
                                    {item.homeScore}
                                </td>
                                <td className="PickemSchedulePage-teamNameHome">
                                    <Link to={{pathname: `/pickem-picks/${competitionId}`}} onClick={() => updateCompetitionData({name: item.homePicksetName, picksUserId: item.homeCompetitor, isRandomUser: item.isRandomHome})}>
                                        {item.homePicksetName}
                                    </Link>
                                </td>
                            </tr>
                            
                    )
                })

                const table = (
                    <div className="PickemSchedulePage-table">
                            <table>
                                {matchups}
                            </table>
                        </div>

                )
                schedulePage.push(header)
                schedulePage.push(table)
            }
        }

        return schedulePage
    }

    return (
        <PickemLayoutPage
            title="Schedule"
            name={nameString} 
            competitionName={competitionName} 
            competitionDescription={competitionDescription}
        >
            <div className="PickemSchedulePage">
                {!isLoading ? createSchedule() : <Spinner></Spinner>}
            </div>
            
        </PickemLayoutPage>
    );
};

export default withDialog(PickemSchedulePage);
